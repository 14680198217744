<template>
	<div class="anti-drooling">
		<section class="anti-drooling-top-block pt-4 px-8 xl:px-0">
			<div class="hidden lg:block anti-drooling-header lg:container lg:mx-auto relative">
				<div class="header-text header-text-overlay text-left text-2xl font-title font-normal leading-tight absolute top-0 left-4 lg:left-0 z-10 mt-6">
					<h1 class="header-text-top text-left text-white">Anti-Drooling</h1>
					<h1 class="header-text-bottom text-left text-white">Ceremony</h1>
				</div>
			</div>
			<div class="page-top mx-auto h-full flex flex-col justify-between lg:hidden">
				<img
					class="mobile-top block lg:hidden mx-auto"
					src="../assets/img/mobile-anti-drooling-top.png"
					alt=""
				>
				<img
					class="mobile-title block lg:hidden mx-auto"
					src="../assets/img/mobile-anti-drooling-title.svg"
					alt=""
				>
			</div>
			<div class="anti-drooling-content flex flex-col xl:grid xl:grid-cols-13">
				<div class="anti-drooling-content-slider-left xl:col-span-8 2xl:col-span-9 h-full relative hidden lg:block">
					<!-- top swiper -->
					<swiper
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:autoplay="{
  'delay': 5000,
'disableOnInteraction': false
}"
						@swiper="setControlledSwiper"
						@slideChange="onSlideChange"
					>
						<swiper-slide
							v-for="image in slider?.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</swiper>
					<bullets
						:count="slider?.images?.length"
						:activeIndex="activeIndex"
						color="orange"
						direction="column"
						@bulletIndex="slideTo"
						class="flex absolute -right-6 -bottom-0 "
					></bullets>
				</div>

				<div class="content-text xl:col-span-5 2xl:col-span-4 flex flex-col h-full text-left py-8 xl:py-0 xl:px-12 2xl:px-10 3xl:px-16 4xl:px-28 px-6 justify-center w-full">
					<h2 class="content-text-title hidden lg:block">{{ content.title }}</h2>
					<span class="content-decoration hidden lg:block"></span>
					<p
						class="content-text-description"
						v-html="content.subTitle"
					></p>
					<br />
					<img
						src="../assets/img/mobile-anti-drooling-middle.png"
						alt=""
						class="block lg:hidden mx-auto"
					>
					<p
						class="content-text-description content-text-flow"
						v-html="content.description"
					></p>
				</div>
			</div>
		</section>
		<section class="anti-drooling-bottom-block pt-12 xl:pt-36 container mx-auto px-8 lg:px-0">
			<div class="anti-drooling-header">
				<div class="mobile-title lg:hidden flex justify-center">
					<img
						src="../assets/img/mobile-antidrooling-plan.svg"
						alt=""
					>
				</div>
				<div class="anti-drooling-header-text hidden lg:container lg:mx-auto relative lg:flex justify-between items-end">
					<div class="header-text text-left text-2xl font-title font-normal leading-tight">
						<h1 class="header-text-top text-left text-about">Anti-Drooling</h1>
						<h1 class="header-text-bottom text-left text-orange">Ceremony</h1>
					</div>
					<div class="anti-drooling-header-append flex pt-4 lg:pt-0 font-title items-center">

						<h3 class="header-text-left text-left text-xl tracking-wider px-7">{{ header.subTitle }}</h3>
						<h3
							class="header-text-middle text-md tracking-wider leading-normal text-left px-7"
							v-html="header.description"
						></h3>
						<h1 class="header-text-right text-left text-xlxl font-medium leading-loose tracking-widest pl-7">{{ header.title }}</h1>
					</div>
				</div>
			</div>
			<div class="anti-drooling-plan lg:container lg:mx-auto relative flex flex-col lg:flex-none lg:grid grid-cols-2 pt-16">
				<div
					class="plan flex flex-col px-8 md:px-36 lg:px-0 pb-8 lg:pb-0 lg:flex-none lg:grid grid-cols-5"
					v-for="item in plan?.items"
					:key="item.id"
				>
					<img
						:src="item?.coverImage.url"
						class="plan-cover col-span-3 px-0 lg:px-4"
					/>
					<div class="plan-content col-span-2 flex flex-col justify-center font-header tracking-widest">
						<h2 class="content-name text-left font-bold text-xl lg:text-lg text-orange lg:text-secondary">{{ item.name }}</h2>
						<h1 class="content-price text-left text-4xl font-bold font-title">{{ numberWithCommas(item.price) }} <span class="text-lg">元</span></h1>
						<span class="content-decoration"></span>
						<p
							class="content-description text-left text-orange text-mdlg leading-relaxed"
							v-html="item.contents"
						></p>
					</div>
				</div>

			</div>
			<div class="anti-drooling-mobile-header py-8 lg:hidden px-8 md:px-36 ">
				<h3 class="mobile-header-title text-lgxl text-orange font-bold tracking-wider leading-none">{{ header.subTitle }}</h3>
				<h3
					class="mobile-header-text font-body text-orange text-md tracking-wider text-left pt-6"
					v-html="joinParagraphs(header.description)"
				></h3>
			</div>
		</section>
	</div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { fetchData } from "../utils/fetchApi";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Bullets from "../components/Bullets.vue";
import { numberWithCommas, joinParagraphs } from "../utils/helpers";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default {
	components: {
		Swiper,
		SwiperSlide,
		Bullets,
	},
	setup() {
		const store = useStore();
		const enabledMenuId = store.state.enabledMenuId;
		// define all refs of blocks id
		const sliderId = ref(null);
		const contentId = ref(null);
		const planId = ref(null);
		const headerId = ref(null);

		// define all forms reactive fields
		const slider = reactive({});
		const content = reactive({});
		const plan = reactive({});
		const header = reactive({
			title: "",
			subTitle: "",
			description: "",
		});

		// set swiper instances ref
		const controlledSwiper = ref(null);

		const idTitleObjArray = [
			{ id: sliderId, title: "Anti-Drooling Slider" },
			{ id: contentId, title: "Anti-Drooling Introduction" },
			{ id: planId, title: "Anti-Drooling Price List" },
			{ id: headerId, title: "Anti-Drooling Header Text" },
		];

		// set @swiper instances
		const setControlledSwiper = (swiper) =>
			(controlledSwiper.value = swiper);

		// set Bullets slide to @bulletsIndex
		const slideTo = (index) => controlledSwiper.value.slideTo(index);

		// set current slide index on <swiper>
		const activeIndex = ref(0);
		const onSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeIndex.value = swiper.activeIndex;
		};

		onMounted(async () => {
			await fetchData.menuBlocks(enabledMenuId, idTitleObjArray);

			await fetchData.slider(sliderId, slider);
			await fetchData.content(contentId, content);
			await fetchData.priceList(planId, plan);
			await fetchData.content(headerId, header);
		});

		return {
			slider,
			content,
			plan,
			header,
			activeIndex,
			setControlledSwiper,
			slideTo,
			onSlideChange,
			numberWithCommas,
			joinParagraphs,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.swiper-container {
	height: 40rem;
}
.swiper-slide {
	// width: 80% !important;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.swiper-wrapper {
	height: 100% !important;
	align-items: center !important;
}

.anti-drooling {
	&-top-block {
		.content-text-flow {
			@apply text-orange;
			@media (max-width: 1023px) {
				@apply text-secondary pt-8;
			}
		}
	}
}

.anti-drooling-header-append {
	height: 4rem;
	.header-text {
		&-left,
		&-middle,
		&-right {
			border-left: 2px solid #ceb689;
		}
		&-left {
			@apply text-about;
		}
		&-middle {
			@apply text-about;
		}
	}
}

.content {
	&-decoration {
		width: 1.15rem;
		height: 0.2rem;
		@apply bg-about my-8;
	}
	&-text {
		&-title {
			@apply text-xlxl font-medium leading-loose tracking-widest;
		}
		&-subtitle {
			@apply leading-loose text-secondary font-normal text-sm;
		}
		&-description {
			@apply leading-loose font-body text-sm;
			@media (max-width: 1023px) {
				@apply text-base font-normal font-body tracking-wider;
			}
		}
	}
}

.plan {
	&-content {
		.content-decoration {
			width: 1.15rem;
			height: 0.2rem;
			@apply bg-orange my-8;
		}
	}
}

.mobile-header {
	&-title {
		padding: 1.25rem;
		border: 2px solid $orange;
		display: inline-block;
	}
}
</style>
<style lang="scss">
el-svg-icon {
	svg {
		width: 1rem;
		height: 1rem;
	}
}
</style>